import React, { useState } from 'react';
import Slider from "react-slick";
import slickLeft from '../img/leftArrow.svg';
import slickRight from '../img/rightArrow.svg';
import { logEvent } from 'firebase/analytics';
import { analytics } from '../App';

const handleClick = (eventName) => {

    logEvent(analytics, eventName, { name: eventName, purpose: 'perform_action' });
}

const CaseSlider = (props) => {
    const [currentSlide, setCurrentSlide] = useState(0);
    const [eventName, setEventName] = useState(props.eventName);
    
    const settings = {
        dots: false,
        infinite: false,
        variableWidth: true,
        adaptiveHeight: true,
        speed: 800,
        slidesToShow: 1,
        centerMode: true,
        centerPadding: '0%',
        slidesToScroll: 1,
        prevArrow: <div><img src={slickLeft} alt="prev" /></div>,
        nextArrow: <div><img src={slickRight} alt="Next" /></div>,
        responsive: [{
            breakpoint: 900,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
              variableWidth: false,
              adaptiveHeight: false,
            }
          },
          {
             breakpoint: 500,
             settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
                variableWidth: false,
                adaptiveHeight: false,
             }
          }],
        beforeChange: (current, next) => {
            const nextPresent = next + 1;
            if (next > current) {
                handleClick(eventName + "right_arrow_button_" + nextPresent.toString());
            } else {
                handleClick(eventName + "left_arrow_button_" + nextPresent.toString());
            }
            
            setCurrentSlide(next);
        },

    };
    return (
        <div id='useCase' className={`caseSliderContainer ${props.dataArrFooter ? 'caseSliderContainerFooter' : ""}`}>
            <h1>{props.title}</h1>
            {
                props.dataArrFooter ? <Slider {...settings} className="caseSlider">
                        {props.dataArrFooter?.map((slide, index) => {
                        return (
                            <div className={`caseSlide ${index === currentSlide - 1 ? 'slick-left' : index === currentSlide + 1 ? 'slick-right' : ''}`} key={index}>
                                <div className='caseSlideInner'>
                                    <img src={slide.caseSlideImg} alt="" />
                                    <p>{slide.description}</p>
                                    <span>{slide.subtitle}</span>
                                </div>
                            </div>
                        )

                    })}
                </Slider>
                : <Slider {...settings} className="caseSlider">
                    {props.dataArr?.map((slide, index) => {
                        return (
                            <div className={`caseSlide ${index === currentSlide - 1 ? 'slick-left' : index === currentSlide + 1 ? 'slick-right' : ''}`} key={index}>
                                <div className='caseSlideInner'>
                                    <h1>{slide.title}</h1>
                                    <p>{slide.description}</p>
                                  {slide.description2 &&  <p>{slide.description2}</p>}
                                </div>
                                <div className='sliderImg'>
                                    <img src={slide.caseSlideImg} alt="" />
                                    <div className='slidesCount flex_align'>{`${index + 1}/${props.dataArr.length}`}</div>
                                </div>
                            </div>
                        )
                    })}
                </Slider>
            }

        </div >
    )
}

export default CaseSlider
