import React from 'react'
import Slider from "react-slick";
const partnerArr = [
    {
        partnerImg: require("../img/partnerImg1.png").default,
    },
    {
        partnerImg: require("../img/partnerImg2.png").default,
    },
    {
        partnerImg: require("../img/partnerImg3.png").default,
    },
    {
        partnerImg: require("../img/partnerImg4.png").default,
    },
    {
        partnerImg: require("../img/partnerImg5.png").default,
    },
]
const Partner = () => {
    const settings = {
        dots: false,
        infinite: true,
        variableWidth: true,
        autoplay:true,
        autoplaySpeed:3000,
        speed: 800,
        arrows: false,
        slidesToShow: 1,
        centerMode: false,
        slidesToScroll: 1,
        responsive: [{
            breakpoint: 900,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
            }
          },
          {
             breakpoint: 400,
             settings: {
                slidesToShow: 1,
                slidesToScroll: 1
             }
          }],
    };
    return (
        <div className='partnerWrap'>
            <div class="container">
                <h1>Partners</h1>
                <ul className='d-md-flex d-none'>
                    {
                        partnerArr.map((partnerData) => (
                            <li>
                                <img src={partnerData.partnerImg} alt='' />
                            </li>
                        ))

                    }


                </ul>
                <Slider {...settings} className="d-md-none">
                        {
                            partnerArr.map((partnerData) => (
                                <div>
                                    <img src={partnerData.partnerImg} alt='' />
                                </div>
                            ))

                        }

                </Slider>
            </div>
        </div>
    )
}

export default Partner
