import React from 'react';

const LatestNewsSlideInner = ({windowSmall, title, description, buttonAction, buttonText, caseSlideImg, arrLength, currentSlide}) => {
    return (
        <>
            <div className={"caseSlideInner"} style={windowSmall ? {height: "auto", padding:30} : {maxWidth:"100%", padding:30, margin:0}}>
                <div className={"d-flex justify-content-between align-items-center"} style={{marginBottom: "10px"}}>
                    <div style={{
                        backgroundColor: "#E8F0F6",
                        padding: "9px",
                        borderRadius: "5px"
                    }}>Press Release</div>
                    <div>August 12,2024</div>
                </div>
                <h1 style={{fontSize:"30px"}}>{title}</h1>
                <p style={{fontSize:"18px"}}>{description}</p>
                <button type={"button"}
                        className={"btn btn-primary btn-sm"}
                        onClick={buttonAction}
                        style={windowSmall ? {
                            backgroundColor: "#352C51",
                            borderColor: "#352C51",
                            marginTop: "5px",
                            fontSize: "12px",
                            alignSelf: "center",
                            width:"100%",
                            borderRadius:"10px",
                            paddingTop:"5px",
                            paddingBottom:"5px"
                        } : {
                            maxWidth: "40%",
                            backgroundColor: "#352C51",
                            borderColor: "#352C51",
                            fontSize:"10px",
                            marginTop:"20px",
                            borderRadius:"10px",
                            paddingLeft:"38px",
                            paddingRight:"38px",
                            paddingTop:"6px",
                            paddingBottom:"6px"
                        }}
                >
                    {buttonText}
                </button>
            </div>
            <div className='sliderImg' style={windowSmall ? {height:"300px"} : {}}>
                <img src={caseSlideImg} alt=""/>
                {arrLength > 1 && <div className='slidesCount flex_align'>{`${currentSlide+1}/${arrLength}`}</div>}
            </div>
        </>
    );
};

export default LatestNewsSlideInner;