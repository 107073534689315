import React, { useState } from 'react';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import ButtonC from '../components/ButtonC'

import { logEvent } from 'firebase/analytics';
import { analytics } from '../App';

const waitForElm = (selector) => {
  return new Promise(resolve => {
    if (document.querySelector(selector)) {
      return resolve(document.querySelector(selector));
    }

    const observer = new MutationObserver(mutations => {
      if (document.querySelector(selector)) {
        observer.disconnect();
        resolve(document.querySelector(selector));
      }
    });

    // If you get "parameter 1 is not of type 'Node'" error, see https://stackoverflow.com/a/77855838/492336
    observer.observe(document.body, {
      childList: true,
      subtree: true
    });
  });
}

const navArr = [
  {
    title: "The Need",
    link: "#need",
    eventName: "navbar_the_need_button",
  },
  {
    title: "The Solution",
    link: "#solution",
    eventName: "navbar_the_solution_button",
  },
  {
    title: "How does it work",
    link: "#useCase",
    eventName: "navbar_use_cases_button",
  },
  {
    title: "Team",
    link: "#team",
    eventName: "navbar_team_button",
  },
  {
    title:"Latest News",
    link:"#latestNews",
    eventName:"navbar_latest_news_button"
  }
]

function NavbarM(props) {
  const [isClicked, setIsClicked] = useState(false);
  const handleButtonClick = () => {
    // if (!isClicked) {
    //   props.setNewsPage(false)
    // }
    setIsClicked(!isClicked);

    // document.body.classList.toggle('body-clicked', isClicked);
    document.body.classList.toggle('bodyFixed');
  };

  const handleNavLinkClick = (e, secLink, eventName) => {
    console.log("handleNavClick", e, secLink, eventName)
    if (!!e) {
      e.preventDefault();
    }
    props.setNewsPage(false)
    waitForElm(secLink).then((section) => {

      if (section) {
        var sectionPosition = section.getBoundingClientRect().top + window.pageYOffset - 145;
        if (window.innerWidth < 1560) {
          sectionPosition = section.getBoundingClientRect().top + window.pageYOffset - 90;
        }
        if (window.innerWidth < 1370) {
          sectionPosition = section.getBoundingClientRect().top + window.pageYOffset - 75;
        }
        if (window.innerWidth < 800) {
          sectionPosition = section.getBoundingClientRect().top + window.pageYOffset - 85;
        }
        if (window.innerWidth < 568) {
          sectionPosition = section.getBoundingClientRect().top + window.pageYOffset - 70;
        }

        logEvent(analytics, eventName, {
          name: eventName,
          purpose: 'perform_action'
        });

        window.scrollTo({ top: sectionPosition, behavior: 'smooth' });
      }


      setIsClicked(false);

      if (window.innerWidth < 800) {
        document.querySelector('.menuToggle').click();
      }
    })
  };

  const navBrand = () => {
    logEvent(analytics, 'navbar_home_button', { name: 'navbar_home_button', purpose: 'perform_action' });
    window.scrollTo({ top: 0, behavior: 'smooth' });
    props.setNewsPage(false)
    if (window.innerWidth < 800) {

      if (!isClicked) return
      document.querySelector('.menuToggle').click();
    }
  };

  return (
    <Navbar expand="lg" className={`${isClicked ? 'navBar__' : ''}`}>
      <Container>
        <Navbar.Brand style={{display:"block"}} href="#home" className='me-0 p-0' onClick={navBrand}><a href='#home'><img src={props.logo} alt="" /></a></Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" className='menuToggle' onClick={handleButtonClick} />

        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="ms-auto">
            {
              navArr.map((navData) => (
                <Nav.Link href={navData.link} onClick={(e) => handleNavLinkClick(e, navData.link, navData.eventName)}>{navData.title}</Nav.Link>
              ))
            }
            <a onClick={ (e) => handleNavLinkClick(e, "#contactUS", "navbar_contact_us_button")}><ButtonC btnTxt="Contact Us" btnBgClr="#78F2A2" btnClr="#000" btnHeight="44px" className="navBtn" /></a>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}

export default NavbarM;