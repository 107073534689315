import React from 'react';
import ButtonC from './ButtonC';
import phoneImg from '../img/phoneImg.png'
import CaseSlider from './CaseSlider';
import Boat from './Boat';
import danBanner from "../img/danBanner/danBanner.png"
import dukeSeal from "../img/danBanner/dukeSeal.png"
import DanBanner from "./DanBanner";
const slideArray = [
    {
        title: 'Meet Linda',
        description: 'Linda is an active and involved grandmother of four. She does pilates twice a week and always goes to her annual checkups on schedule.',
        caseSlideImg: require("../img/caseSlide1.png").default,
    },
    {
        title: 'Life-changing event',
        description: "At her most recent checkup, Linda's doctor observed concerning symptoms, resulting in a stage 3 colon cancer diagnosis after tests. Now a patient, she faces the challenges of treatments, alongside worries about her family, finances, and an uncertain future.",
        caseSlideImg: require("../img/caseSlide2.png").default,
    },
    {
        title: 'An extra layer of support',
        description: 'Then, her health plan sends her an invitation to Epilog.',
        description2: "Linda opens Epilog on her phone. She receives a short tutorial about how Epilog works, the holistic approach, and the concept of patient-driven care.",
        caseSlideImg: require("../img/caseSlide3.png").default,
    },
    {
        title: 'Life-management is key',
        description: 'Linda continues with her treatment plan alongside Epilog, which accompanies her through the various aspects of her life. Epilog will be there to help with her daily needs in the time and place right for her.',
        caseSlideImg: require("../img/caseSlide4.png").default,
    },
    {
        title: 'Controlling symptoms',
        description: 'Epilog helps Linda identify and manage her symptoms.',
        caseSlideImg: require("../img/caseSlide5.png").default,
    },
    {
        title: 'Key medical junctions',
        description: 'It helps her to consider how different medical decisions will affect her quality of life.',
        caseSlideImg: require("../img/caseSlide6.png").default,
    },
    {
        title: 'Help with life decisions',
        description: 'It helps her handle personal decisions like how and when to share the news with her loved ones, and how to think about her legacy.',
        caseSlideImg: require("../img/caseSlide7.png").default,
    },
    {
        title: 'Daily quality of life',
        description: "It keeps track of how she's doing and helps improve Linda's daily quality of life.",
        description2: 'Her Epilog experiece is made up of dozens of personalized short sessions, only a few minutes each, aimed to give her a supportive net and a sense of control.',
        caseSlideImg: require("../img/caseSlide8.png").default,
    },
];
const Need = () => {
    const needArr = [
        {
            needBox: "#FFF",
            needImg: require("../img/needImg1.png").default,
            needTxt: (
                <p>
                    <strong>1 in 4 Medicare dollars</strong> are spent on people during their last 12 months of life, making it the largest medical expense. Sadly, most of it is NOT a result of what people want.
                </p>
            )
        },
        {
            needBox: "#DBE0FF",
            needImg: require("../img/needImg2.png").default,
            needTxt: (<p> Though needs-based care programs are highly recognized for their effectiveness, uptake and engagement levels are staggeringly low. </p>)
        },
        {
            needBox: "#2D2147",
            needImg: require("../img/needImg3.png").default,
            needTxt: (<p style={{ color: "#fff" }}>The main challenge is identifying the right time for each person to transition to needs-based care, and engaging them in a cost-effective fashion. </p>)
        }
    ];

    return (
        <section className='heroNxt' id='need'>
            <div className='container'>
                <div className='heroNxtInner'>
                    <div className='needWrap'>
                        {
                            needArr.map((needArrData, index) => (
                                <div className='needBox' style={{backgroundColor: needArrData.needBox}} key={index}>
                                    <div className="needImg">
                                        <img src={needArrData.needImg} alt=""/>
                                    </div>
                                    <h3>{needArrData.needTxt}</h3>
                                </div>
                            ))
                        }
                    </div>
                    <DanBanner/>
                </div>

                <div className='solutionWrap' id='solution'>
                    <ul>
                        <li><h1>Why Epilog?</h1></li>
                        <li><h2 className="solutionText">
                            Epilog builds trusted relationships with people, identifies unmet needs, reduces care gaps
                            with 24/7 support, and utilizes behavioral economics to engage people in making timely
                            transitions to needs-based care.
                            <br/><br/>
                            Compassionate technology helps generate faster and much higher adoption rates at
                            significantly lower costs.
                        </h2></li>
                        <li>
                            <p>
                                The Key to an easy transition is through careful, early and personalized support based
                                on what people really want and taking their life circumnstances into consideration.
                            </p>
                        </li>
                        {/* <li>
                            <p>
                                
                            </p>
                        </li> */}
                        <li>
                            <p>
                            The Epilog solution is a patient-centric, holistic, personalized companion for people diagnosed with serious illness which helps shift from disease management to life management to enable a timely transition to quality of life focused care.
                            </p>
                        </li>
                    </ul>
                    <div className='phoneImg'>
                        <img src={phoneImg} alt="" />
                    </div>
                </div>
                <CaseSlider title="How does it work?" dataArr={slideArray} eventName="use_cases_" />
            </div>
                <Boat />
        </section>
    );
};

export default Need;
