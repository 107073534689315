import React, {useState} from 'react';
import Slider from "react-slick";
import slickLeft from "../img/leftArrow.svg";
import slickRight from "../img/rightArrow.svg";
import LatestNewsSlideInner from "./LatestNewsSlideInner";
import {logEvent} from "firebase/analytics";
import {analytics} from "../App";

const handleClick = (eventName) => {

    logEvent(analytics, eventName, { name: eventName, purpose: 'perform_action' });
}

const LatestNewsSlider = ({dataArr, title, event_name}) => {
    const settings = {
        dots: false,
        infinite: false,
        variableWidth: true,
        adaptiveHeight: true,
        speed: 800,
        slidesToShow: 1,
        centerMode: true,
        centerPadding: '0%',
        slidesToScroll: 1,
        prevArrow: <div><img src={slickLeft} alt="prev" /></div>,
        nextArrow: <div><img src={slickRight} alt="Next" /></div>,
        responsive: [{
            breakpoint: 900,
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
                variableWidth: false,
                adaptiveHeight: false,
            }
        },
            {
                breakpoint: 500,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    variableWidth: false,
                    adaptiveHeight: false,
                }
            }],
        beforeChange: (current, next) => {
            const nextPresent = next + 1;
            if (next > current) {
                handleClick(eventName + "right_arrow_button_" + nextPresent.toString());
            } else {
                handleClick(eventName + "left_arrow_button_" + nextPresent.toString());
            }

            setCurrentSlide(next);
        },

    };
    const [currentSlide, setCurrentSlide] = useState(0);
    const [eventName, setEventName] = useState(event_name);
    const windowSmall = window.innerWidth < 500
    return (
        <div id={''} className={"caseSliderContainer"}>
            <h1 style={windowSmall? {color:"black", marginBottom:"30px"}:{color:"black", marginBottom:"30px"}}>{title}</h1>
            {dataArr.length === 1 ? (
                <div
                    className={`caseSlide shadow`}
                    key={`nadavSlide-0`}
                    style={{display:"flex", flexGrow:1, justifyContent:"space-between"}}>
                    <LatestNewsSlideInner
                        windowSmall={windowSmall}
                        title={dataArr[0].title}
                        eventName={eventName}
                        description={dataArr[0].description}
                        buttonText={dataArr[0].buttonText}
                        buttonAction={dataArr[0].buttonAction}
                        caseSlideImg={dataArr[0].caseSlideImg}
                        arrLength={1}
                    />
                </div>
            ): <Slider {...settings} className={"caseSlider"}>
                {dataArr.map((slide, index) => (
                    <div
                        className={`shadow caseSlide ${index === currentSlide - 1 ? 'slick-left' : index === currentSlide + 1 ? 'slick-right' : ''}`}
                        key={`nadavSlide-${index}`}
                    >
                        <LatestNewsSlideInner
                            windowSmall={windowSmall}
                            title={slide.title}
                            eventName={eventName}
                            description={slide.description}
                            buttonText={slide.buttonText}
                            buttonAction={slide.buttonAction}
                            caseSlideImg={slide.caseSlideImg}
                            arrLength={dataArr.length}
                            currentSlide={currentSlide}
                        />
                    </div>
                ))}
            </Slider>}
        </div>
    )
};

export default LatestNewsSlider;