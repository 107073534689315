import React, {useEffect} from 'react';
import danBanner from "../img/danBanner/danBanner.png";
import dukeSeal from "../img/danBanner/dukeSealNew.png";

const DanBanner = () => {
    const isPhone = window.innerWidth < 500
    useEffect(() => {
        console.log(isPhone)
    }, [window.width]);

    return isPhone ? <div className={"d-flex flex-column justify-content-around gap-0 p-5"} style={{
        backdropFilter: 'blur(10px)',
        borderRadius: '10px',
        marginTop: "250px",
        backgroundColor:"rgba(210, 216, 254, 0.56)"
    }}>
        <div className={"d-flex flex-row justify-content-around"}>
            <img
                style={{height: "150px", width: "167px"}} alt={"Dan Ariely"} src={danBanner}/>
            <img style={{width: "114px", height: "90px"}} alt={"Duke Seal"} src={dukeSeal}/>
        </div>
        <div className={"d-flex flex-column justify-content-center"}>
            <h1 className={"text-start mt-2"} style={{fontFamily: "Space Grotesk", fontSize: "22px"}}><strong>Do you have insights about this
                stage of life to share?</strong></h1>
            <p className={"text-start"}>Join our community research study led by Prof. Dan Ariely and help others by sharing your experiences from managing serious illnesses.</p>
            <p className={"text-start mt-3"}><strong>If you have something to share, we'd love to hear.</strong></p>
            <button className={"btn btn-success mt-3"}><a style={{color: "white"}} href={"http://www.thelifeweshouldlive.com/"}>Share my story</a></button>
        </div>
    </div> : (
        <div className={"d-flex flex-row justify-content-around gap-5 p-5"} style={{
            backdropFilter: 'blur(3px)',
            borderRadius: '10px',
            marginTop: "250px",
            backgroundColor:"rgba(210, 216, 254, 0.56)"
        }}>
            <div className={"d-flex flex-column justify-content-center"}><img
                style={{height: "150px", minWidth: "167px"}} alt={"Dan Ariely"} src={danBanner}/>
            </div>
            <div className={"d-flex flex-column justify-content-center"}>
                <h1 style={{fontFamily: "Space Grotesk", fontSize: "22px"}}><strong>Do you have insights about this
                    stage of life to share?</strong></h1>
                <p className={"text-start"}>Join our community research study led by Prof. Dan Ariely and help others by sharing your experiences from managing serious illnesses.</p>
                <button className={"btn btn-success mt-3 align-self-center"}><a style={{color: "white"}}
                                                                                href={"http://www.thelifeweshouldlive.com/"}>Share
                    my story</a></button>
            </div>
            <div>
                <img style={{minWidth: "163px", height:"128px"}} alt={"Duke Seal"} src={dukeSeal}/>
            </div>
        </div>
    );
};

export default DanBanner;