import React from 'react';
import tickIcon from '../img/tickIcon.svg'

const ButtonC = (props) => {
  const mainBtnC = {
    background: props.btnBgClr,
    color: props.btnClr,
    height: props.btnHeight,
  };

  return (
    <div className='d-flex align-items-center gap-1 justify-content-center w-100'>
      <a onClick={props.onClick} className={`flex_align mainBtn ${props.className}`} style={mainBtnC}>{props.btnTxt}
      
      </a>
      {props.submitIcon && <img src={tickIcon} alt="" />}
    </div>
  );
};

export default ButtonC;
