import React from 'react'
import boatImg from '../img/boatImg.png'

const boatArr = [
    {
        checkMark: require("../img/checkMark.svg").default,
        boatTxt: (
            <p>
                Epilog provides patients <strong>24/7 support</strong> to manage physical and emotional challenges, personalized guidance on how to minimize gaps in care and improve daily living, and assistance with decision-making when the time is right for them.
            </p>
        )
    },
    {
        checkMark: require("../img/checkMark.svg").default,
        boatTxt: (
            <p>
                Health plans and providers receive <strong>actionable data</strong> for the first time. Epilog provides a timely and accurate view into the needs and wants of people so they can be helpful and efficient while utilizing in-person care.
            </p>
        )
    }

]
const Boat = () => {
    return (
        <div className='boatWrap'>
             <div className="boatImg">
                    <img src={boatImg} alt="" />
                </div>
            <div class="container">
                <ul>
                    {
                        boatArr.map((boatData) => (
                            <li>
                                <img src={boatData.checkMark} alt="" />
                                <p> {boatData.boatTxt}</p>
                            </li>
                        ))
                    }
                </ul>
            </div>
        </div>
    )
}

export default Boat
