import React from 'react';
import mainLogo from '../img/mainLogo.svg'
import birdImg from '../img/birdsHero.png'
import OurVision from "./OurVision";

const HeroSec = () => {
    return (
        <div className='container heroContainer' id="home">
            <div className="heroInner h-100">
                <div className="heroLogo">
                <img src={mainLogo} alt="" />
                </div>
                <div className="birdImg">
                    <img src={birdImg} alt="" />
                </div>
                <h1>Redefining Care in Life's Last Chapter</h1>
                <h3>
                Life is a handful of meaningful events; birth of a child, a glass of wine, the landing of man on the moon.
                <br/>
                This should not change even if we are seriously ill. 
                <br/>
                {/*<strong>This is our mission.</strong>*/}
                {/* <br/>
                And when done right, compassionately and efficiently, a better human experience comes at a significantly lower expense.  */}
                </h3>
                <OurVision/>
            </div>
        </div>
    );
};

export default HeroSec;
