import React from 'react'
import ButtonC from './ButtonC';
import linkedIn from '../img/LinkedIn.svg'
import { logEvent } from 'firebase/analytics';
import { analytics } from '../App';

const teamArr = [
    {
        img: require("../img/teamMember1.png").default,
        title: "Tal Shapsa Heiman",
        designation: <ButtonC btnTxt="CEO" btnBgClr="#DBE0FF" btnClr="#2D2147" btnHeight="34px" className="mx-auto" />,
        detail: "Formerly senior economist in Wall Street (Barclays Bank) and Israel's ministry of finance (Budgets), and founding partner at Out of Line, a startup co-foundry hub with expertise in behavioral product strategy.",
        linkedInIcon: <a href='https://www.linkedin.com/in/tal-shapsa-heiman-9a5ab256' className='linkedIcon' target="_blank" ><img src={linkedIn} alt='' /></a>,
        liEvent: "linkedin_tal_button",
    },
    // {
    //     img: require("../img/teamMember2.png").default,
    //     title: "Ofir Shauman",
    //     designation: <ButtonC btnTxt="CPO" btnBgClr="#DBE0FF" btnClr="#2D2147" btnHeight="34px" className="mx-auto" />,
    //     detail: "A serial entrepreneur combining commercial and social activism and solving complex problems with technological tools. Founder of companies in media, proptech and consumer products with a solid technology background (IDF).",
    //     linkedInIcon: <a href='https://www.linkedin.com/in/ofir-shauman' className='linkedIcon' target="_blank" ><img src={linkedIn} alt='' /></a>,
    //     liEvent: "linkedin_ofir_button",
    // },
    {
        img: require("../img/teamMember3.png").default,
        title: "Or Bar-Tal",
        designation: <ButtonC btnTxt="CTO" btnBgClr="#DBE0FF" btnClr="#2D2147" btnHeight="34px" className="mx-auto" />,
        detail: "Experience R&D Lead in successful startups & corporations in the past 10 years. Proficient in the Data / ML/ Architecture /Cybersecurity domains. Ex co-founder & CTO in TechStars backed company.",
        linkedInIcon: <a href='https://www.linkedin.com/in/or-bar-tal-99906582' className='linkedIcon' target="_blank" ><img src={linkedIn} alt='' /></a>,
        liEvent: "linkedin_tor_button",
    },
    {
        img: require("../img/teamMember4.png").default,
        title: "Prof. Dan Ariely",
        designation: <ButtonC btnTxt="Chief Behavioral Officer" btnBgClr="#DBE0FF" btnClr="#2D2147" btnHeight="34px" className="mx-auto mainBtnTeamTitle" />,
        detail: "A world leading expert in behavioral economics. founder of the center of advanced hindsight at Duke, co-founder of several successful companies, and three-time NY Times best-selling author.",
        linkedInIcon: <a href='https://www.linkedin.com/in/danariely' className='linkedIcon' target="_blank" ><img src={linkedIn} alt='' /></a>,
        liEvent: "linkedin_dan_button",
    },
    {
        img: require("../img/teamMember5.png").default,
        title: "Dr. Ron Saber",
        designation: <ButtonC btnTxt="Chief Medical Officer" btnBgClr="#DBE0FF" btnClr="#2D2147" btnHeight="34px" className="mx-aut mainBtnTeamTitle" />,
        detail: "A family medicine and palliative care physician for the past 18 years. A thought leader and writer, and th founder and chief medical officer of Sabar Health, Israel's largest home hospital.",
        linkedInIcon: <a href='https://www.linkedin.com/in/ron-sabar' className='linkedIcon' target="_blank" ><img src={linkedIn} alt='' /></a>,
        liEvent: "navbar_the_need_button",
        liEvent: "linkedin_ron_button",
    },
]

const handleClick = (eventName) => {
    logEvent(analytics, eventName, { name: eventName, purpose: 'perform_action' });
}

const Team = (props) => {
    return (
        <div id='team'>
            <h1>{props.title}</h1>
            <ul className='teamRow'>
                {
                    teamArr.map((teamData) => (
                        <li onClick={ () => handleClick(teamData.liEvent) }>
                            <div className='teamImg'><img src={teamData.img} alt='' /></div>
                            <h1>{teamData.title}</h1>
                            <span>{teamData.designation}</span>
                            <p>{teamData.detail}</p>
                            {teamData.linkedInIcon}
                        </li>
                    ))
                }
            </ul>
        </div>
    )
}

export default Team
