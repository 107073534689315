import React, { useEffect, useState } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import NavbarM from './components/Navbar'
import './fonts.css'
import './custom.scss'
import mainLogo from './img/mainLogo.svg'
import HeroSec from './components/HeroSec';
import Need from './components/Need';
import Partner from './components/Partner';
import Service from './components/Service';
import FooterU from './components/FooterU';
import Footer from './components/Footer';
import article1Image from './img/latest_news/article.png'
import frame1 from "./img/latest_news/frame1.png"
import frame2 from "./img/latest_news/frame2.png"
import frame3 from "./img/latest_news/frame3.png"


import { initializeApp } from 'firebase/app';
import { getAnalytics, logEvent } from 'firebase/analytics';

const firebaseConfig = {
  apiKey: 'AIzaSyBeTqFoFeKfZmmHf3vHAWAjq15oIJGkA8Y',
  authDomain: 'epilog-website-fd010.firebaseapp.com',
  projectId: 'epilog-website-fd010',
  storageBucket: 'epilog-website-fd010.appspot.com',
  messagingSenderId: '248008146761',
  appId: '1:248008146761:web:cde371b36b2f9e2a37e498',
  measurementId: 'G-4SJ2REZ5D3'
};

const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

export { analytics };


function App() {
  const [scroll, setScroll] = useState(false);
  useEffect(() => {
     // Log an event when the component mounts (user enters the project)
     logEvent(analytics, 'enter_the_website', { name: 'enter_the_website', purpose: 'perform_action' });

    const handleScroll = () => {
      const offset = window.scrollY;
      if (offset > 100) {
        setScroll(true);
      } else {
        setScroll(false);
      }
    };

    window.addEventListener('scroll', handleScroll);

    // Cleanup: remove the event listener when the component is unmounted
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);
  const urlParams = new URLSearchParams(window.location.search);
  const latestNewsParam = urlParams.get('discoveryhealth')!==null
  const [isNewsPage, setIsNewsPage] = useState(latestNewsParam);
    useEffect(() => {
        console.log("isNewsPage changed")
        isNewsPage && setTimeout(()=>{
            window.scrollTo({top:0, behavior:"smooth"})
        },150)
    }, [isNewsPage, setIsNewsPage]);
  if (isNewsPage) {
      return (
          <>
              <div className="heroMain">
                  <div className={`heroSec ${scroll ? 'heroSec__' : ""}`} style={window.innerWidth >500 ? {height:'200px'} : {height:50}}>
                      <NavbarM logo={mainLogo} setNewsPage={setIsNewsPage}/>
                  </div>
                  <div className={"container"} style={window.innerWidth < 500
                      ? {backgroundColor:"rgba(255,255,255,0.5)"}
                      : {backgroundColor:"rgba(255,255,255,0.5)",borderRadius:"30px", padding:"50px"}}
                  >
                      {window.innerWidth > 500 ? <img style={{alignSelf: "center"}} src={article1Image} alt={""}/> :
                          <div>
                              <img style={{alignSelf: "center"}} src={frame1} alt={""}/>
                              <img style={{alignSelf: "center"}} src={frame2} alt={""}/>
                              <img style={{alignSelf: "center"}} src={frame3} alt={""}/>
                          </div>}
                  </div>
              </div>

              <Footer/>
          </>
      )
  }
  return (
    <>
      <div className="heroMain">
        <div className={`heroSec ${scroll ? 'heroSec__' : ""}`} >
          <NavbarM logo={mainLogo} setNewsPage={setIsNewsPage}/>
          <HeroSec />
        </div>
        <Need />
      </div>
      <div className="serviceBg">
      <Partner />
      <Service title="Epilog successfully engages people in making life’s important transitions" />
      </div>
      <FooterU setNewsPage={setIsNewsPage} />
      <Footer />
    </>
  );
}

export default App;
