import React from 'react';

const OurVision = () => {
    const isPhone = window.innerWidth <500
    return isPhone? <div className={"d-flex flex-column justify-content-center gap-0 p-3 mt-5"} style={{
        backdropFilter: 'blur(10px)',
        border: '2px solid white',
        borderRadius: '10px',
    }}>
        <div>
            <p style={{fontFamily: "Space Grotesk", color: "#2D2147", lineHeight: "20px"}}
               className={"text-center"}>We are experts in health, decision making and technology, driven by theory
                and data</p>
            <br/>
            <p style={{fontFamily: "Space Grotesk", color: "#2D2147", lineHeight: "20px"}}
               className={"text-center"}>We create a no compromise agent for all patients, with the goal of ensuring dignity, support,
                and clarity for everyone.</p>
        </div>
    </div> : (
        <div className={"d-flex flex-row justify-content-center gap-3 p-3 mt-5"} style={{
            backdropFilter: 'blur(10px)',
            border: '2px solid white',
            borderRadius: '10px',
        }}>
            <div style={{width:"700px"}}>
                <p style={{fontFamily: "Space Grotesk", color: "#2D2147", fontSize: "18px", lineHeight: "25px"}}
                   className={"text-center"}>We are experts in health, decision making and technology, driven by theory
                    and data. We create a no compromise agent for all patients, with the goal of ensuring dignity, support, and clarity for everyone.</p>
            </div>
        </div>
    );
};

export default OurVision;