import React from 'react'
import Team from './Team'

const servicesArr = [
    {
        img: require("../img/serviceImg1.svg").default,
        title: "45%",
        subTitle: "Improvement",
        detail: "in uptake of needs-based care, palliative and hospice programs",
    },
    {
        img: require("../img/serviceImg2.svg").default,
        title: "18%",
        subTitle: "Increase",
        detail: "in member-driven “difficult conversations”",
    },
    {
        img: require("../img/serviceImg3.svg").default,
        title: "34%",
        subTitle: "Improvement",
        detail: "in member comprehension of their medical condition and options",
    },
    {
        img: require("../img/serviceImg4.svg").default,
        title: "25%",
        subTitle: "Enrollment",
        detail: "digital enrollment rate",
    },
    {
        img: require("../img/serviceImg5.svg").default,
        title: "60%",
        subTitle: "Engagement",
        detail: "digital engagement for a minimum of 5 months",
    },
]
const Service = (props) => {
    return (
        <div className='servicesWrap'>
            <div class="container">
                <h1>{props.title}</h1>
                <ul>
                    {
                        servicesArr.map((servicesData) => (
                            <li>
                                <div className='serviceImg'><img src={servicesData.img} alt='' /></div>
                                <h1>{servicesData.title}</h1>
                                <span>{servicesData.subTitle}</span>
                                <p>{servicesData.detail}</p>
                            </li>
                        ))
                    }
                </ul>
                
            </div>
            <div class="containerTeam">
                <Team title="Team" />
            </div>
        </div>
    )
}

export default Service
